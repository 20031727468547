<template>
    
<section class="hero is-medium" :style="{ backgroundImage: 'url(' + backgroundImageUrl + ')', backgroundSize: 'cover', backgroundPosition: 'center' }">
  <div class="hero-body">
    <div class="">
    <p class="title">ЮКА - агрегатор новостроек Москвы и Московской области.</p>
    <p class="subtitle">500+ новостроек Москвы и Московской области от лучших застройщиков без комиссии и переплат</p>
  </div>
    </div>
</section>

<nav class="level has-background-black-ter has-text-white p-5">
  <div class="level-item has-text-centered">
    <div>
      <p class="title has-text-white  pb-2">500+</p>
      <p class="heading">Жилых комплексов в Москве и Московской области .</p>
    </div>
  </div>
  <div class="level-item has-text-centered">
    <div>
      <p class="title has-text-white  pb-2">5%*</p>
      <p class="heading">Самая низкая ставка по семейной ипотеке
(МКБ, лицензия ЦБ РФ № 1978 от 06.05.2016)</p>
    </div>
  </div>
  <div class="level-item has-text-centered">
    <div>
    <p class="title has-text-white  pb-2">0 руб</p>
      <p class="heading">Наши клиенты не платят комиссию, потому что мы работаем напрямую от застройщиков</p>

    </div>
  </div>
</nav>
</template>

<script>
export default {
  data() {
    return {
      backgroundImageUrl: require('@/assets/hero.webp') // Замените путь на соответствующий
    };
  }
};
</script>

<style scoped>
.heading {
  display: block;
  font-size: 11px;
  letter-spacing: 1px;
  margin-bottom: 5px;
  text-transform: uppercase;
  max-width: 200px;
}
</style>