<template>

<nav class="navbar is-link" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" href="http://192.168.31.250:8080">
      <img  src="../assets/logo.jpg" width="113" height="80">
    </a>

    <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu">
    <div class="navbar-start is-link">
      <a class="navbar-item">
        Оставить заявку
      </a>
      <router-link to="/top-apartments" class="navbar-item">Лучшие предложения</router-link>
      <div class="navbar-item has-dropdown is-hoverable">

        <router-link to="/about" class="navbar-link">О компании</router-link>

        <div class="navbar-dropdown">
            <router-link to="/about" class="navbar-item">О нас</router-link>
            <router-link to="/vacancies" class="navbar-item">Вакансии</router-link>
            <router-link to="/contact" class="navbar-item">Контакты</router-link>
        </div>
      </div>
    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <a href="tel:+123456789" class="button">
            <strong>Позвонить</strong>
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>


</template>


