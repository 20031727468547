<script setup>
import SiteHeader from './SiteHeader.vue'
import HeroComponent from './HeroComponent.vue'
import PartnersComponent from './PartnersComponent.vue';
</script>

<template>

<SiteHeader />
<HeroComponent />

<div class="filter">
<div class="container pt-4">
<div class="columns">

  <div class="column">
    <div class="field">
            <div class="select">
                <!-- <label for="subway">Select Subway:</label> -->
                <select id="subway" v-model="subwayFilter">
                    <option value="">Метро:</option>
                    <option v-for="subway in subways" :key="subway.name" :value="subway.name">{{ subway.name }}</option>
                </select>
            </div>
        </div>
  </div>

  <div class="column">
    <div class="field">
            <div class="control">
                <!-- <label for="price">Price Range:</label> -->
                <input class="input" type="number" id="priceFrom" v-model="priceFrom" placeholder="Цена от:" />
            </div>
        </div>
  </div>

  <div class="column">
    <div class="field">
        <div class="control">
            <input class="input" type="number" id="priceTo" v-model="priceTo" placeholder="Цена дo" />
        </div>
        </div>
  </div>

  <div class="column">
    <div class="field">
        <div class="control">
            <!-- <label for="floor">Floor:</label> -->
            <input class="input" type="number" id="floor" v-model="floor" placeholder="Этаж"  />
        </div>
    </div>
  </div>

  <div class="column">
    <div class="field">
        <div class="control">
            <!-- <label for="rooms">Number of Rooms:</label> -->
            <input class="input" type="number" id="rooms" v-model="rooms" placeholder="Кол-во комнат"  />
        </div>
    </div>
  </div>

  <div class="column">
    <div class="field">
        <button class="button is-link is-fullwidth" @click="fetchApartments">Показать</button>
    </div>
  </div>

</div>
</div>
</div>






    <div class="container pt-4">

      <!-- <div>
        <label for="subway">Select Subway:</label>
        <select id="subway" v-model="subwayFilter">
          <option value="">All</option>
          <option v-for="subway in subways" :key="subway.name" :value="subway.name">{{ subway.name }}</option>
        </select>
      </div>
      <div>
        <label for="price">Price Range:</label>
        <input type="number" id="priceFrom" v-model="priceFrom" placeholder="From" />
        <input type="number" id="priceTo" v-model="priceTo" placeholder="To" />
      </div>
      <div>
        <label for="floor">Floor:</label>
        <input type="number" id="floor" v-model="floor" />
      </div>
      <div>
        <label for="rooms">Number of Rooms:</label>
        <input type="number" id="rooms" v-model="rooms" />
      </div>
      <button @click="fetchApartments">Filter</button> -->




      <div v-if="loading">Loading...</div>

        <div class="columns is-multiline" v-if="apartments.length > 0">
            <router-link
                 v-for="apartment in apartments"
                 :key="apartment._id"
                 :to="'/apartment/' + apartment._id"
                 class="column is-3"
                >
            <!-- <div class="column is-3" v-for="apartment in apartments" :key="apartment._id"> -->
                <div class="card p-4 card_size">
                <h2 class="title is-5">ЖК: {{ apartment.block_name }}</h2>


                <figure class="image pb-3">
                    <div v-if="apartment.plan && apartment.plan.length > 0">
                    <div v-for="(image, index) in apartment.plan" :key="index">
                        <img :src="image" alt="Apartment Plan" style="max-width: 50%; max-height: 200px;" />
                    </div>
                    </div>
                </figure>

                <p><strong>Цена:</strong> {{ apartment.price }} руб.</p>
                <p><strong>Этаж:</strong> {{ apartment.floor }}</p>
                <p><strong>Кол-во комнат:</strong> {{ apartment.room }}</p>
                <p v-if="apartment.block_subway_name && apartment.block_subway_name.length > 0">
                  <strong>Метро:</strong> {{ apartment.block_subway_name.join(', ') }}
                </p>
                <p v-else>
                    <strong>Метро:</strong> No subway information available
                </p>
                <p><strong>Адрес:</strong> {{ apartment.block_address }}</p>
                <p><strong>Площадь:</strong> {{ apartment.area_total }}</p>
                <p><strong>Застройщик:</strong> {{ apartment.block_builder_name }}</p>
                </div>
            <!-- </div> -->
            </router-link>
        </div>
      
    </div>

     
      <div v-if="error">{{ error.message }}</div>

  <PartnersComponent />
  </template>
  
  <script>

  export default {
    data() {
      return {
        loading: false,
        error: null,
        apartments: [],
        priceFrom: null,
        priceTo: null,
        floor: null,
        rooms: null,
        plan: null,
        subwayFilter: "",
        subways: [],
        block_builder_name: null,
        block_address: null,
        area_total: null,
        pagination: {
          limit: 20,
          offset: 0,
        },
      };
    },
    mounted() {
      this.fetchSubways();
      window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    watch: {
      priceFrom: "fetchApartmentsOnChange",
      priceTo: "fetchApartmentsOnChange",
      floor: "fetchApartmentsOnChange",
      rooms: "fetchApartmentsOnChange",
      subwayFilter: "fetchApartmentsOnChange",
    },
    methods: {
      fetchApartmentsOnChange() {
        // Reset pagination offset when filter parameters change
        this.pagination.offset = 0;
        this.fetchApartments();
      },
  
      async fetchSubways() {
        try {
          const response = await this.fetchGraphQL(`
        query {
          subways {
            name
          }
        }
      `);
  
          const data = response.subways || [];
          this.subways = data;
        } catch (error) {
          console.error('Error fetching subways:', error);
        }
      },
      async fetchApartments() {
        this.loading = true;
        this.error = null;
  
        try {
          const response = await this.fetchGraphQL(`
        query ($filter: ApartmentFilter, $pagination: PaginationInput) {
          apartments(filter: $filter, pagination: $pagination) {
            _id
            building_name
            price
            floor
            room
            block_name
            block_subway_name
            plan
            block_builder_name
            area_total
            block_address
          }
        }
      `, {
        filter: {
          price_from: this.priceFrom,
          price_to: this.priceTo,
          floor: this.floor,
          room: this.rooms,
          block_subway_name: this.subwayFilter,
        },
        pagination: this.pagination,
      });
  
      const data = response.apartments || [];
      
      // Update apartments data based on pagination
      if (this.pagination.offset === 0) {
        // Reset apartments if offset is 0
        this.apartments = data;
      } else {
        // Append to existing apartments if offset is greater than 0
        this.apartments = [...this.apartments, ...data];
      }
  
      // Increment offset for the next pagination
      this.pagination.offset += this.pagination.limit;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  },
      async fetchGraphQL(query, variables = {}) {
        const response = await fetch('http://147.45.103.42:4000/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query,
            variables,
          }),
        });
  
        const result = await response.json();
        return result.data || {};
      },
      handleScroll() {
        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;
        const bodyHeight = document.body.offsetHeight;
  
        if (scrollY + windowHeight >= bodyHeight - 200) {
          this.fetchApartments();
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add your component styles here if needed */
  /* .filter {
    position: sticky;
    top: 0;
    z-index: 1000;
    background: white;
  } */

  .card_size{
    min-height: 520px;
  }

  .image{
    left: 50%;
    transform: translateX(-30%);
  }
  </style>
  