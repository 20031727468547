import { createRouter, createWebHistory } from 'vue-router';

import ApartmentsViewer from './components/ApartmentsViewer.vue';
import ApartmentDetails from './components/ApartmentDetails.vue';
import  OffersPage from './components/OffersPage.vue';
import CompanyPage from './components/CompanyPage.vue';
import AboutPage from './components/AboutPage.vue';
import VacanciesPage from './components/VacanciesPage.vue';
import ContactPage from './components/ContactPage.vue';
import TopApartsPage from './components/TopApartsPage.vue';

const routes = [
  { path: '/', component: ApartmentsViewer },
  { path: '/apartment/:id', component: ApartmentDetails, props: true },
  { path: '/offers', component: OffersPage }, // Для пути '/offers' отображаем компонент Offers
  { path: '/company', component: CompanyPage }, // Для пути '/company' отображаем компонент Company
  { path: '/about', component: AboutPage }, // Для пути '/about' отображаем компонент About
  { path: '/vacancies', component: VacanciesPage }, // Для пути '/vacancies' отображаем компонент Vacancies
  { path: '/contact', component: ContactPage }, // Для пути '/contact' отображаем компонент Contact
  { path: '/top-apartments', component: TopApartsPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;