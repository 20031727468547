<template>
    <section class="container">
        <div class="title is-5">Партнеры</div>
        <div class="columns is-multiline">
            <div class="column is-2"><img class="img_partners" src="../assets/companies/1.jpg"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/2.jpg"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/3.jpg"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/4.jpg"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/5.jpg"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/6.jpg"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/7.png"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/8.jpg"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/9.jpg"></div>
            <div class="column is-2"><img class="img_partners" src="../assets/companies/10.jpg"></div>
        </div>

        <!-- <div class="fixed-grid has-5-cols">
            <div class="grid">
                <div class="cell">Cell 1</div>
                <div class="cell">Cell 2</div>
                <div class="cell">Cell 3</div>
                <div class="cell">Cell 4</div>
                <div class="cell">Cell 5</div>
                <div class="cell">Cell 6</div>
                <div class="cell">Cell 7</div>
                <div class="cell">Cell 8</div>
                <div class="cell">Cell 9</div>
                <div class="cell">Cell 10</div>
                <div class="cell">Cell 11</div>
                <div class="cell">Cell 12</div>
            </div>
        </div> -->
    </section>
</template>

<style scoped>
    .img_partners {
        max-width: 150px;
        max-height: 100px;;
    }
</style>